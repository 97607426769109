import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'
import Image, { GatsbyImageFluidProps } from 'gatsby-image'
import { IllustrationEssayImage } from './types'
import ReactPlayer from 'react-player'
import { isMobile } from 'react-device-detect'

type Props = {
    headercap: string
    title: string
    images: IllustrationEssayImage[]
    className?: string
}

type ItemProps = {
    essayTitle: string
    image: IllustrationEssayImage
    idx: number
}

const italicize = (s: string) => s.replace('(', '').replace(')', '').replace('*', '<i className="text-gray-500">').replace('*', '</i>')

const Item = ({ image, idx, essayTitle }: ItemProps) => {
  return (
    <div className="relative max-w-7xl mx-auto py-16 pt-0 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">{idx + 1}</h2>
            <h3 dangerouslySetInnerHTML={{__html: italicize(image.title)}} className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div style={isMobile ? {height: 384, width: 404 } : {}} className="md:aspect-w-12 md:aspect-h-7 lg:aspect-none">
                    {
                      (image.image as GatsbyImageFluidProps).fluid ? (
                        <Image className="rounded-md shadow-md" fluid={(image.image as GatsbyImageFluidProps).fluid} />
                      ) : (
                        <img className="rounded-md shadow-md" src={image.image as string} />
                      )
                    }
                </div>
                <figcaption className="mt-3 flex text-sm text-gray-500">
                  <CameraIcon className="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-2">{essayTitle.includes('plants') ? 'ISABELLA TAN/THE VARSITY' : image.photocap }</span>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <div className="text-lg text-gray-500" dangerouslySetInnerHTML={{ __html: image.content }}>
              </div >
            </div>
          </div>
        </div>
      </div>
    )
}

export default function Card2 ({ title, images, className, headercap }: Props) {
  return (
    <div className={`bg-white overflow-hidden ${className}`}>
        {images.map((img, idx) => <Item essayTitle={title} image={img} idx={idx} />)}
        <p className="text-center">{headercap}</p>
    </div>
  )
}

