import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Image, { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'
import IllustrationCardDeck from '../components/illustration-cards'

import { IllustrationEssay } from '../components/illustration-cards/types'
import { GraphQLQuery } from '../types'
import ArticleHeader from '../components/articleHeader'
import Card2 from '../components/illustration-cards/card2.0'
import { string } from 'prop-types'

type Props = {
  allMarkdownRemark: {
    nodes: {
      frontmatter: {
        title: string
        photoIndex: true | null
        author: string
        date: string
        headercap: string | undefined
        description: string
        slug: string
        caption: string
        photocap: string | undefined
        featuredImage: {
          childImageSharp: GatsbyImageFluidProps
          publicURL: string
        }
        image?: {
          childImageSharp: GatsbyImageFluidProps
          publicURL: string
        }
        video: string
      }
      fileAbsolutePath: string
      html: string
    }[]
  }
}

const resolveEssay = (nodes: Props['allMarkdownRemark']['nodes']): IllustrationEssay => {
  nodes.forEach(node => !node.frontmatter.image && console.log(node))
  const { frontmatter: { title, author, description, featuredImage, slug, headercap }, html } = nodes.find(node => node.frontmatter.photoIndex === true)!
  const images = nodes.filter(node => !node.frontmatter.photoIndex).sort((a, b) => 
  parseInt(a.fileAbsolutePath.split('/').slice(-1)[0]) - 
  parseInt(b.fileAbsolutePath.split('/').slice(-1)[0])
).map(node => ({
    title: node.frontmatter.title,
    content: node.html,
    photocap: node.frontmatter.photocap,
    image: node.frontmatter.image!.childImageSharp || node.frontmatter.image!.publicURL
  }))
  console.log(headercap)
  return {
    title,
    slug,
    author,
    description,
    content: html,
    headercap: headercap!,
    featuredImage: featuredImage?.childImageSharp || featuredImage?.publicURL,
    images
  }
}

const IllustrationPage = ({ data: { allMarkdownRemark }}: GraphQLQuery<Props>) => {
  const essay = resolveEssay(allMarkdownRemark.nodes)
  console.log(essay)
  return (
    <Layout postTitle={essay.title}
    postImage={essay.featuredImage}
    postDescription={essay.description}
    isHome={false}
    slug={essay.slug}>
    <div className="bg-gray-50 text-gray-600">
      <ArticleHeader backgroundImage={essay.featuredImage} title={essay.title} description={essay.description} author={essay.author} />
      <div className="max-w-4xl mx-auto pb-12">
        <div className="px-4 md:px-0">
          {essay.content ? 
          <div className="bg-white overflow-hidden">
            <div className="px-4 py-5 sm:p-6">
              <div dangerouslySetInnerHTML={{__html: essay.content}}>
              </div>
            </div>
          </div>
           : ''
          }
          <Card2 headercap={essay.headercap} title={essay.title} className="mx-auto" images={essay.images} />
        </div>
      </div>
      </div>
    </Layout>
  )
}

export default IllustrationPage

export const query = graphql`
  query($regex: String!){
    allMarkdownRemark(filter: {fileAbsolutePath: { regex: $regex } }) {
      nodes {
        frontmatter {
          slug
          photoIndex
          photocap
          title
          description
          headercap
          author
          featuredImage {
            publicURL 
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image {
            publicURL 
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
        fileAbsolutePath
      }
    }
  }  
`
